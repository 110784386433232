/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --pink: #efc9c4;
  --green: #678480;
  --blue: #53869e;
  --shadow: 16px 16px 48px #2e364330;
}

.App {
  min-height: 100vh;
  font-family: "Work Sans", serif;
  font-weight: 450;
  font-style: normal;
  font-size: 18px;
  display: flex;
  background-color: #e3f6fc;
}

/************ For smaller screen sizes ************/
@media (max-width: 768px) {
  .App {
    width: 100vw;
    font-size: 80%;
    flex-direction: column;
    align-items: flex-start;  
    justify-content: flex-start;
  }
}