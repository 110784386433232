.main-container {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0px 45px 50px;
}

.window-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}
  
.tabs-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.content-container {
    border-radius: 30px 0 30px 30px;
    border-color: var(--green); 
    border-width: 10px;
    border-style: solid;
}

.tab-content {
    padding: 0px 30px 30px 20px;
    background-color: #feeee1;
    overflow: hidden;
    border-radius: 20px 0 20px 20px;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

h1 {
    margin-left: 20px;
    padding-right: 40px;
}

h4 {
    margin-right: 50px;
}


/************ For smaller screen sizes ************/
@media (max-width: 768px) {
    .main-container {
        margin-right: 50px;
    }

    .window-container {
        width: 90%;
    }
  
    .tabs-container {
        justify-content: flex-end;
        flex-direction: row;
    }
  
    .content-container {
        width: 100%;
        border-radius: 20px;
    }
  
    .tab-content {
        font-size: 16px;
        border-radius: 10px;
    }

    header {
        flex-direction: column;
    }

    h1 {
        margin-left: 0;
        padding-right: 0;
    }

    h4 {
        margin: 0;
    }
  }