.project-card {
    position: relative;
    width: 100%;
    height: 245px;
    border-radius: 30px;
    box-shadow: 16px 16px 48px #60667030;
    border: 3px solid var(--blue);
    overflow: hidden;
    color: white;
    height: 100%;
    width: 100%;
    background-color: var(--blue);
    text-align: center;
    padding: 0px 10px 2px;
}

.proj-stack {
    font-style: italic;
}

.link-icon {
    fill: white;
    padding: 5px;
    transition: transform 0.3s, color 0.3s;
}

.link-icon:hover {
    transform: scale(1.3);
}